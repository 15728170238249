import request from '@/utils/request'

export default {
  getList(params) {
    return request({
      url: '/v1/equipment',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/equipment/${id}`,
      method: 'get'
    })
  },
  create(data) {
    return request({
      url: '/v1/equipment',
      method: 'post',
      data
    })
  },
  update(data) {
    return request({
      url: '/v1/equipment',
      method: 'put',
      data
    })
  },
  delete(data) {
    return request({
      url: `/v1/equipment`,
      method: 'delete',
      data
    })
  },
  appendParts(data) {
    return request({
      url: '/v1/equipmentParts',
      method: 'post',
      data
    })
  },
  editParts(data) {
    return request({
      url: '/v1/equipmentParts',
      method: 'put',
      data
    })
  },
  deleteParts(data) {
    return request({
      url: '/v1/equipmentParts',
      method: 'delete',
      data
    })
  },
  excel(data) {
    return request({
      url: '/v1/equipmentExcel',
      method: 'post',
      data
    })
  },
  factoryTree(params) {
    return request({
      url: '/v1/factoryTree',
      method: 'get',
      params
    })
  }
}
